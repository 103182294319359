import React from 'react';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import Header from "../../components/header";
import Footer from '../../components/footer';
import { SiGnuprivacyguard } from "react-icons/si";
import Images from '../../assests';



const Privacy = () => {
    return (
        <>
            {/* <Header /> */}
            <Box
                sx={{
                    width: '100%',
                    height:'300px',
                    backgroundImage: `url(${Images.trainSchedule})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    position:'relative'


                }}
            >
                   <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)", // Add a semi-transparent overlay
                            borderRadius: 2,
                          }}
                        ></Box>
          <Typography variant="h3" align="center" sx={{ padding: "30px 0", fontSize: '25px',color:'white',zIndex:1 }}>Privacy Policy
          </Typography>

            </Box>
  
            <Divider />
            <Container style={{ padding: "30px 0", display: 'flex', alignItems: 'center', width: '85%', margin: '30px auto' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h4" sx={{ paddingBottom: "20px", textAlign: 'left', fontSize: '25px' }}>Privacy Policy</Typography>
                        <Typography paragraph align="left">
                            At Track my Train, accessible from https://trackkmytrain.com, one of our main priorities is the privacy of our visitors.
                            This Privacy Policy document contains types of information that is collected and recorded by Track my Train and how we use it.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ paddingBottom: "30px", textAlign: 'left', fontSize: '25px' }} >Consent</Typography>
                        <Typography paragraph align="left" >
                            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                        </Typography>
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ paddingBottom: "30px", textAlign: 'left', fontSize: '25px' }} >Information We Collect</Typography>
                        <Typography paragraph align="left">
                            The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ paddingBottom: "30px", textAlign: 'left', fontSize: '25px' }} >How We Use Your Information</Typography>
                        <Typography paragraph align="left">
                            We use the information we collect in various ways, including to:
                            <ul>
                                <li>Provide, operate, and maintain our website</li>
                                <li>Improve, personalize, and expand our website</li>
                                <li>Understand and analyze how you use our website</li>
                                <li>Develop new products, services, features, and functionality</li>
                            </ul>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ paddingBottom: "30px", textAlign: 'left', fontSize: '25px' }} >Log Files</Typography>
                        <Typography paragraph align="left">
                            Track my Train follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ paddingBottom: "30px", textAlign: 'left', fontSize: '25px' }} >Cookies and Web Beacons</Typography>
                        <Typography paragraph align="left">
                            Like any other website, Track my Train uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ paddingBottom: "30px", textAlign: 'left', fontSize: '25px' }} >Google DoubleClick Dart Cookie</Typography>
                        <Typography paragraph align="left">
                            Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ paddingBottom: "30px", textAlign: 'left', fontSize: '25px' }} >Advertising Partners Privacy Policies</Typography>
                        <Typography paragraph align="left">
                            Some of advertisers on our site may use cookies and web beacons. Our advertising partners include Google.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ paddingBottom: "30px", textAlign: 'left', fontSize: '25px' }} >CCPA Privacy Rights (Do Not Sell My Personal Information)</Typography>
                        <Typography paragraph align="left">
                            Under the CCPA, among other rights, California consumers have the right to:
                            <ul>
                                <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
                                <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
                            </ul>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ paddingBottom: "30px", textAlign: 'left', fontSize: '25px' }} >GDPR Data Protection Rights</Typography>
                        <Typography paragraph align="left">
                            Every user is entitled to the following:
                            <ul>
                                <li>The right to access</li>
                                <li>The right to rectification</li>
                                <li>The right to erasure</li>
                                <li>The right to restrict processing</li>
                                <li>The right to object to processing</li>
                                <li>The right to data portability</li>
                            </ul>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ paddingBottom: "30px", textAlign: 'left', fontSize: '25px' }} >Children's Information</Typography>
                        <Typography paragraph align="left">
                            Track my Train does not knowingly collect any Personal Identifiable Information from children under the age of 13.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
      
            <Footer />
        </>
    );
};

export default Privacy;
